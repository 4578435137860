import React from "react";
import Lottie from "react-lottie";
import { data } from "./data";
import styles from "./RightLottie.module.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const RightLottie = () => {
  return (
    <div className={styles.container}>
      <Lottie
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
        isClickToPauseDisabled
      />
    </div>
  );
};

export default RightLottie;
