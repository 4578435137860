import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { logo, decor } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/HoptechnicLogo.svg" }) {
        publicURL
      }
      decor: file(relativePath: { eq: "images/test/hopTechnicHero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return { logo: logo.publicURL, decor };
};
