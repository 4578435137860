import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const data = useStaticQuery(graphql`
    query {
      appStoreImage: file(
        relativePath: { eq: "images/footer/app-store-badge.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      googlePlayImage: file(
        relativePath: { eq: "images/footer/google-play-badge.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hoptechnicImage: file(
        relativePath: { eq: "images/footer/hoptechnic-badge.jpeg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      facebook: file(
        relativePath: { eq: "images/footer/facebook.svg" }
      ) {
        publicURL
      }
      instagram: file(
        relativePath: { eq: "images/footer/instagram.svg" }
      ) {
        publicURL
      }
      VGFImage: file(
        relativePath: { eq: "images/footer/vgf-badge.jpeg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return data;
};
