import React from "react";
import classnames from "classnames";
import { ReactSVG } from "react-svg";
import { Link } from "gatsby";
import { links } from "@routes/data";
import useGetImage from "./useGetImage";
import styles from "./Sidebar.module.scss";

const Sidebar = ({ handleStateChange, menuOpen }) => {
  const sidebarStyles = classnames(styles.overlay, {
    [styles.active]: menuOpen,
  });

  const { logo, decor } = useGetImage();

  return (
    <div className={sidebarStyles} onClick={handleStateChange}>
      <div className={styles.sidebar}>
        <div className={styles.linkBox}>
          {links.map(({ link, label }) => (
            <Link
              key={link}
              to={link}
              className={styles.link}
              activeClassName={styles.active}
            >
              {label}
            </Link>
          ))}
        </div>
        <div className={styles.decor}>
          <img
              className={styles.decorImage}
              src={decor.childImageSharp.fluid.src}
              alt="decor"
          />
        </div>
        <div className={styles.logo}>
          <ReactSVG src={logo} className={styles.logoImage} />
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
