import React, { useState } from "react";
import { Location } from "@reach/router";
import classnames from "classnames";
import { Link } from "gatsby";
import { ReactSVG } from "react-svg";
import Burger from "./components/burger";
import Sidebar from "./components/sidebar";
import useGetLogo from "./useGetImage";
import NavigationBox from "./components/navigationBox";
import useResizeWidth from "@hooks/useResizeWidth";
import styles from "./Header.module.scss";

const mobileScreenWidth = 576;

const HOME_PAGE = "/";

const Header = ({ location }) => {
  const headerClasses = classnames(styles.header, {
    [styles.homeHeader]: location.pathname === HOME_PAGE,
  });
  const { logo } = useGetLogo();

  const { width } = useResizeWidth();

  const [menuOpen, setMenuOpen] = useState(false);
  const handleSetMenuOpen = () => setMenuOpen(!menuOpen);
  const handleSetMenuCLose = () => setMenuOpen(false);
  return (
    <>
      {width && width < mobileScreenWidth && (
        <Burger handleStateChange={handleSetMenuOpen} menuOpen={menuOpen} />
      )}

      <header className={headerClasses}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <Link to="/" className={styles.link}>
              <ReactSVG src={logo} className={styles.logoImage} />
            </Link>
          </div>

          {width && width < mobileScreenWidth ? (
            <Sidebar
              menuOpen={menuOpen}
              handleStateChange={handleSetMenuCLose}
            />
          ) : (
            <div className={styles.navigationBox}>
              <NavigationBox />
            </div>
          )}
        </div>
      </header>
    </>
  );
};

const ComponentWithLocation = props => {
  return (
    <Location>
      {locationProps => <Header {...locationProps} {...props} />}
    </Location>
  );
};
export default ComponentWithLocation;
