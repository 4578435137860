import React from "react";
import useGetImages from "./useGetImages";
import FooterLottie from "../footerLottie";
import styles from "./CustomFooter.module.scss";
import { useLocation } from "@reach/router";

const CustomFooter = () => {
  const { appStoreImage, googlePlayImage, hoptechnicImage, VGFImage, facebook, instagram } = useGetImages();
  const data = [
    {
      title: "AppStore",
      image: appStoreImage,
      link: "https://apps.apple.com/us/app/id1518077385",
    },
    {
      title: "GooglePlay",
      image: googlePlayImage,
      link: "https://play.google.com/store/apps/details?id=com.vgfinc.hoptechnics",
    },
    {
      title: "Hoptechnic",
      image: hoptechnicImage,
      link: "https://www.instagram.com/HopTechnic/",
    },
    {
      title: "VGF",
      image: VGFImage,
      link: "https://vgfinc.com",
    },
    {
      title: "Facebook",
      image: {childImageSharp: {fluid: {src: facebook.publicURL} }},
      link: "https://www.facebook.com/virgilgamachefarms",
    },
    {
      title: "Instagram",
      image: {childImageSharp: {fluid: {src: instagram.publicURL} }},
      link: "https://www.instagram.com/virgil_gamache_farms/",
    },
  ];

  const location = useLocation();



  return (
    <footer className={styles.footer}>
      <div className={styles.lottieWrap}>
        <FooterLottie />
      </div>
      <div className={styles.linksWrap}>
        {data.map(({ title, image, link }) => (
          <a
            href={link}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
            key={title}
          >
            <img
              className=
                { 
                  (location.pathname === "/app" || location.pathname === "/app/" ) ? styles[title] : 
                    (
                      styles[title] === (styles.AppStore) || styles[title] === (styles.GooglePlay) 
                        ? styles.iconsNoDisplay : styles[title]
                    )
                }
              src={image.childImageSharp.fluid.src}
              alt={title}
            />
          </a>
        ))}
      </div>
      <div className={styles.contactWrap }>
        <a
            href="tel:5098656422"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.phone}
        >
          509.865.6422
        </a>
        <address className={styles.address}>
            6371 Fort Road, Toppenish, WA 98948
        </address>
      </div>
    </footer>
  );
};

export default CustomFooter;
