import React from "react";
import Lottie from "react-lottie";
import { data } from "./data";
import styles from "./FooterLottie.module.scss";


const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const FooterLottie = () => {
  return (
      <Lottie
          options={defaultOptions}
          isStopped={false}
          isPaused={false}
          isClickToPauseDisabled
      />
  );
};

export default FooterLottie;
