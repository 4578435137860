import React from "react";
import { Location } from "@reach/router";
import posed, { PoseGroup } from "react-pose";
import styles from "./Transition.module.scss";

const SETTINGS = {
  DELAY: 200,
  DELAY_CHILDREN: 0,
  DURATION: 500,
};

const Transition = ({ children, location }) => {
  const RoutesContainer = posed.div({
    enter: {
      opacity: 1,
      transition: { duration: SETTINGS.DURATION },
      delay: SETTINGS.DELAY,
      delayChildren: SETTINGS.DELAY_CHILDREN,
    },
    exit: {
      opacity: 0,
      transition: { duration: SETTINGS.DURATION },
    },
  });

  return (
    <PoseGroup>
      <RoutesContainer key={location.pathname} className={styles.box}>
        {children}
      </RoutesContainer>
    </PoseGroup>
  );
};

const ComponentWithLocation = props => {
  return (
    <Location>
      {locationProps => <Transition {...locationProps} {...props} />}
    </Location>
  );
};

export default ComponentWithLocation;
