import React from "react";
import Header from "../header";
import Footer from "../footer";
import Decor from "./components/decor";
import Transition from "@components/transition";

import styles from "./Layout.module.scss";
import "@styles/index.scss";

const Layout = ({ children }) => {
  return (
    <div className={styles.wrap}>
      <Header />

      <div className={styles.body}>
        <Transition>{children}</Transition>
      </div>

      <Footer />
      <Decor />
    </div>
  );
};

export default Layout;
