import LeftLottie from "./components/leftLottie";
import React from "react";
import RightLottie from "./components/rightLottie";
import classnames from "classnames";
import styles from "./Decor.module.scss";

const Decor = () => {
  const decorGroupClassnames = classnames(styles.decor, styles.decorGroup);
  const decorCirclesClassnames = classnames(styles.decor, styles.decorCircles);

  return (
    <>
      <div className={decorGroupClassnames}>
        <LeftLottie />
      </div>
      <div className={decorCirclesClassnames}>
        <RightLottie />
      </div>
    </>
  );
};

export default Decor;
