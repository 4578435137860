export const links = [
  {
    link: "/about-us",
    label: "ABOUT US",
  },
  {
    link: "/services",
    label: "LAB SERVICES",
  },
  {
    link: "/contact",
    label: "CONTACT",
  },
  {
    link: "/blog/all",
    label: "POINTS OF INTEREST",
  },
  {
    link: "/harvest-optimization",
    label: "HARVEST OPTIMIZATION",
  },
];
