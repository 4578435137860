import React from "react";
import { Link } from "gatsby";
import { links } from "@routes/data";
import styles from "./NavigationBox.module.scss";

const NavigationBox = () => {
  return (
    <div className={styles.box}>
      {links.map(({ link, label }) => (
        <Link
          key={label}
          to={link}
          className={styles.link}
          activeClassName={styles.active}
        >
          {label}
        </Link>
      ))}
    </div>
  );
};

export default NavigationBox;
