export const data = {
	"v": "5.6.3",
	"fr": 24,
	"ip": 0,
	"op": 720,
	"w": 575,
	"h": 80,
	"nm": "Site Longer",
	"ddd": 0,
	"assets": [],
	"fonts": {
		"list": [{
			"fName": "ProximaNova-Light",
			"fFamily": "Proxima Nova",
			"fStyle": "Light",
			"ascent": 66.69921875
		}]
	},
	"layers": [{
		"ddd": 0,
		"ind": 1,
		"ty": 5,
		"nm": "HOP VARIETIES",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [12.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16.6666698455811,
						"f": "ProximaNova-Light",
						"t": "HOP VARIETIES",
						"j": 0,
						"tr": 100,
						"lh": 20.0000038146973,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 144,
							"s": [100]
						}, {"t": 168, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 2,
		"ty": 5,
		"nm": "FLAVOR PROFILES",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [192.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "FLAVOR PROFILES",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 240,
							"s": [100]
						}, {"t": 264, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 3,
		"ty": 5,
		"nm": "DEFINED BY SCIENCE",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [336.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "DEFINED BY SCIENCE",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 48,
							"s": [100]
						}, {"t": 72, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 4,
		"ty": 5,
		"nm": "bio chemistry",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [12.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16.6666698455811,
						"f": "ProximaNova-Light",
						"t": "BIO CHEMISTRY",
						"j": 0,
						"tr": 100,
						"lh": 20.0000038146973,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 168,
							"s": [0]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 192,
							"s": [100]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 528,
							"s": [100]
						}, {"t": 552, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 5,
		"ty": 5,
		"nm": "hop analysis",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [192.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "HOP ANALYSIS",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 264,
							"s": [0]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 288,
							"s": [100]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 433,
							"s": [100]
						}, {"t": 457, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 6,
		"ty": 5,
		"nm": "harvest timing",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [336.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "HARVEST TIMING",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 72,
							"s": [0]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 96,
							"s": [100]
						}, {
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 336,
							"s": [100]
						}, {"t": 360, "s": [0]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 7,
		"ty": 5,
		"nm": "HOP VARIETIES 3",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [12.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 16.6666698455811,
						"f": "ProximaNova-Light",
						"t": "HOP VARIETIES",
						"j": 0,
						"tr": 100,
						"lh": 20.0000038146973,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 552,
							"s": [0]
						}, {"t": 576, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 8,
		"ty": 5,
		"nm": "FLAVOR PROFILES 3",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [192.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "FLAVOR PROFILES",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 457,
							"s": [0]
						}, {"t": 481, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 9,
		"ty": 5,
		"nm": "DEFINED BY SCIENCE 3",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 100, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [336.5, 43, 0], "ix": 2},
			"a": {"a": 0, "k": [0, 0, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 100, 100], "ix": 6}
		},
		"ao": 0,
		"t": {
			"d": {
				"k": [{
					"s": {
						"s": 8.33333015441895,
						"f": "ProximaNova-Light",
						"t": "DEFINED BY SCIENCE",
						"j": 0,
						"tr": 100,
						"lh": 9.99999618530273,
						"ls": 0,
						"fc": [0.478, 0.765, 0.341]
					}, "t": 0
				}]
			},
			"p": {},
			"m": {"g": 1, "a": {"a": 0, "k": [0, 0], "ix": 2}},
			"a": [{
				"nm": "Animator 1",
				"s": {
					"t": 0,
					"xe": {"a": 0, "k": 0, "ix": 7},
					"ne": {"a": 0, "k": 0, "ix": 8},
					"a": {"a": 0, "k": 100, "ix": 4},
					"b": 1,
					"rn": 1,
					"sh": 1,
					"s": {
						"a": 1,
						"k": [{
							"i": {"x": [0.833], "y": [0.833]},
							"o": {"x": [0.167], "y": [0.167]},
							"t": 361,
							"s": [0]
						}, {"t": 385, "s": [100]}],
						"ix": 1
					},
					"r": 1
				},
				"a": {"o": {"a": 0, "k": 0, "ix": 9}}
			}]
		},
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 10,
		"ty": 4,
		"nm": "Line Across 2",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 50, "ix": 11},
			"r": {"a": 0, "k": 0, "ix": 10},
			"p": {"a": 0, "k": [565.034, 40.106, 0], "ix": 2},
			"a": {"a": 0, "k": [-817.466, 212.106, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 13, 100], "ix": 6}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0]],
						"o": [[0, 0], [0, 0]],
						"v": [[-816.5, -5.5], [-817, 426.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}, {
		"ddd": 0,
		"ind": 11,
		"ty": 4,
		"nm": "Line Across",
		"sr": 1,
		"ks": {
			"o": {"a": 0, "k": 50, "ix": 11},
			"r": {"a": 0, "k": 90, "ix": 10},
			"p": {"a": 0, "k": [565.714, 39.82, 0], "ix": 2},
			"a": {"a": 0, "k": [-816.786, -6.18, 0], "ix": 1},
			"s": {"a": 0, "k": [100, 24, 100], "ix": 6}
		},
		"ao": 0,
		"shapes": [{
			"ty": "gr",
			"it": [{
				"ind": 0,
				"ty": "sh",
				"ix": 1,
				"ks": {
					"a": 0,
					"k": {
						"i": [[0, 0], [0, 0]],
						"o": [[0, 0], [0, 0]],
						"v": [[-816.5, -5.5], [-817, 426.5]],
						"c": false
					},
					"ix": 2
				},
				"nm": "Path 1",
				"mn": "ADBE Vector Shape - Group",
				"hd": false
			}, {
				"ty": "st",
				"c": {"a": 0, "k": [1, 1, 1, 1], "ix": 3},
				"o": {"a": 0, "k": 100, "ix": 4},
				"w": {"a": 0, "k": 0.5, "ix": 5},
				"lc": 1,
				"lj": 1,
				"ml": 4,
				"bm": 0,
				"nm": "Stroke 1",
				"mn": "ADBE Vector Graphic - Stroke",
				"hd": false
			}, {
				"ty": "tr",
				"p": {"a": 0, "k": [0, 0], "ix": 2},
				"a": {"a": 0, "k": [0, 0], "ix": 1},
				"s": {"a": 0, "k": [100, 100], "ix": 3},
				"r": {"a": 0, "k": 0, "ix": 6},
				"o": {"a": 0, "k": 100, "ix": 7},
				"sk": {"a": 0, "k": 0, "ix": 4},
				"sa": {"a": 0, "k": 0, "ix": 5},
				"nm": "Transform"
			}],
			"nm": "Shape 1",
			"np": 3,
			"cix": 2,
			"bm": 0,
			"ix": 1,
			"mn": "ADBE Vector Group",
			"hd": false
		}],
		"ip": 0,
		"op": 736,
		"st": 0,
		"bm": 0
	}],
	"markers": [],
	"chars": [{
		"ch": "D",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 69.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 18.631], [20.847, 0], [0, 0]],
							"o": [[0, 0], [20.847, 0], [0, -18.43], [0, 0], [0, 0]],
							"v": [[8.359, 0], [29.81, 0], [63.748, -33.536], [29.81, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "D",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -15.71], [17.725, 0]],
							"o": [[0, 0], [0, 0], [17.825, 0], [0, 15.71], [0, 0]],
							"v": [[14.099, -5.237], [14.099, -61.935], [29.81, -61.935], [57.706, -33.536], [29.81, -5.237]],
							"c": true
						},
						"ix": 2
					},
					"nm": "D",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "D",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "E",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 56.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [50.958, 0], [50.958, -5.237], [14.099, -5.237], [14.099, -31.824], [50.253, -31.824], [50.253, -37.061], [14.099, -37.061], [14.099, -61.935], [50.958, -61.935], [50.958, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "E",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "E",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "F",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 54.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -31.824], [50.253, -31.824], [50.253, -37.061], [14.099, -37.061], [14.099, -61.935], [50.958, -61.935], [50.958, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "F",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "F",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "I",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 22.3,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "I",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "I",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "N",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 69.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[56.396, 0], [62.036, 0], [62.036, -67.172], [56.296, -67.172], [56.296, -9.97], [14.2, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -58.008]],
							"c": true
						},
						"ix": 2
					},
					"nm": "N",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "N",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {"ch": " ", "size": 8.33333015441895, "style": "Light", "w": 25.9, "data": {}, "fFamily": "Proxima Nova"}, {
		"ch": "B", "size": 8.33333015441895, "style": "Light", "w": 61.4, "data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 11.179], [7.15, 1.108], [0, 8.459], [11.179, 0], [0, 0]],
							"o": [[0, 0], [12.085, 0], [0, -7.855], [6.042, -1.208], [0, -10.574], [0, 0], [0, 0]],
							"v": [[8.359, 0], [36.456, 0], [55.792, -18.127], [42.7, -34.644], [54.483, -50.052], [35.953, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -6.848], [8.661, 0]],
							"o": [[0, 0], [0, 0], [8.661, 0], [0, 6.949], [0, 0]],
							"v": [[14.099, -37.061], [14.099, -61.935], [35.248, -61.935], [48.441, -49.448], [35.248, -37.061]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 2,
					"ty": "sh",
					"ix": 3,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, -6.848], [8.862, 0]],
							"o": [[0, 0], [0, 0], [9.366, 0], [0, 8.157], [0, 0]],
							"v": [[14.099, -5.237], [14.099, -31.824], [35.651, -31.824], [49.75, -18.53], [35.651, -5.237]],
							"c": true
						},
						"ix": 2
					},
					"nm": "B",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "B",
				"np": 6,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		}, "fFamily": "Proxima Nova"
	}, {
		"ch": "Y",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 61.8,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[28.198, 0], [34.039, 0], [34.039, -28.702], [60.727, -67.172], [53.778, -67.172], [31.119, -34.14], [8.459, -67.172], [1.511, -67.172], [28.198, -28.702]],
							"c": true
						},
						"ix": 2
					},
					"nm": "Y",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "Y",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "S",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 58.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [-11.078, 0], [0, 8.56], [10.776, 3.021], [0, 7.956], [-8.963, 0], [-4.834, -5.841], [0, 0], [9.97, 0], [0, -10.977], [-10.574, -2.921], [0, -9.467], [13.797, 0], [4.33, 5.035]],
							"o": [[5.136, 6.244], [18.127, 0], [0, -13.696], [-9.164, -2.518], [0, -7.251], [7.553, 0], [0, 0], [-5.237, -5.942], [-12.186, 0], [0, 12.387], [9.366, 2.518], [0, 5.74], [-9.567, 0], [0, 0]],
							"v": [[4.633, -9.467], [29.205, 1.208], [53.073, -17.725], [30.313, -37.866], [12.79, -50.656], [28.5, -63.043], [47.736, -54.584], [51.562, -58.813], [28.802, -68.28], [6.747, -50.354], [28.601, -32.025], [47.031, -17.422], [29.306, -4.028], [8.359, -13.797]],
							"c": true
						},
						"ix": 2
					},
					"nm": "S",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "S",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "C",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 67.2,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[-18.832, 0], [-5.942, 7.352], [0, 0], [8.258, 0], [0, 17.322], [-15.61, 0], [-4.23, -5.841], [0, 0], [10.876, 0], [0, -20.645]],
							"o": [[10.876, 0], [0, 0], [-4.23, 5.841], [-15.61, 0], [0, -17.523], [8.258, 0], [0, 0], [-5.74, -7.15], [-18.832, 0], [0, 20.645]],
							"v": [[39.679, 1.208], [64.352, -10.776], [59.518, -13.797], [39.679, -4.028], [11.884, -33.536], [39.679, -63.043], [59.518, -53.275], [64.352, -56.396], [39.679, -68.28], [5.841, -33.536]],
							"c": true
						},
						"ix": 2
					},
					"nm": "C",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "C",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "L",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 50.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[8.359, 0], [46.628, 0], [46.628, -5.237], [14.099, -5.237], [14.099, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "L",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "L",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "A",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 64.7,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[57.202, 0], [63.647, 0], [36.154, -67.172], [29.004, -67.172], [1.511, 0], [7.956, 0], [14.703, -16.516], [50.455, -16.516]],
							"c": true
						},
						"ix": 2
					},
					"nm": "A",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0]],
							"v": [[32.529, -61.533], [48.642, -21.753], [16.516, -21.753]],
							"c": true
						},
						"ix": 2
					},
					"nm": "A",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "A",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "V",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 64.7,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[29.004, 0], [36.154, 0], [63.647, -67.172], [57.001, -67.172], [32.529, -6.445], [8.157, -67.172], [1.511, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "V",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "V",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "O",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 76.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -19.638], [-19.739, 0], [0, 19.638], [19.638, 0]],
							"o": [[0, 19.638], [19.638, 0], [0, -19.638], [-19.739, 0]],
							"v": [[5.841, -33.536], [38.47, 1.208], [71.1, -33.536], [38.47, -68.28]],
							"c": true
						},
						"ix": 2
					},
					"nm": "O",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -17.02], [16.113, 0], [0, 16.919], [-16.315, 0]],
							"o": [[0, 16.919], [-16.315, 0], [0, -17.02], [16.113, 0]],
							"v": [[65.057, -33.536], [38.47, -4.028], [11.884, -33.536], [38.47, -63.043]],
							"c": true
						},
						"ix": 2
					},
					"nm": "O",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "O",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "R",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 59.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 12.186], [11.581, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [9.97, -0.403], [0, -12.286], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[47.736, 0], [54.684, 0], [35.248, -28.198], [53.879, -47.534], [33.435, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -27.795], [28.903, -27.795]],
							"c": true
						},
						"ix": 2
					},
					"nm": "R",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -8.459], [8.862, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 8.459], [0, 0], [0, 0], [0, 0], [8.862, 0]],
							"v": [[47.836, -47.534], [32.932, -33.032], [14.099, -33.032], [14.099, -61.935], [32.932, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "R",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "R",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "P",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 57.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 10.574], [12.891, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [12.79, 0], [0, -10.574], [0, 0], [0, 0]],
							"v": [[8.359, 0], [14.099, 0], [14.099, -27.896], [33.536, -27.896], [53.778, -47.534], [33.536, -67.172], [8.359, -67.172]],
							"c": true
						},
						"ix": 2
					},
					"nm": "P",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}, {
					"ind": 1,
					"ty": "sh",
					"ix": 2,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -8.459], [8.862, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 8.459], [0, 0], [0, 0], [0, 0], [8.862, 0]],
							"v": [[47.736, -47.534], [32.932, -33.133], [14.099, -33.133], [14.099, -61.935], [32.932, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "P",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "P",
				"np": 5,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "H",
		"size": 16.6666698455811,
		"style": "Light",
		"w": 70.3,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[56.699, 0], [62.54, 0], [62.54, -67.172], [56.699, -67.172], [56.699, -37.161], [14.099, -37.161], [14.099, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -31.924], [56.699, -31.924]],
							"c": true
						},
						"ix": 2
					},
					"nm": "H",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "H",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "T",
		"size": 16.6666698455811,
		"style": "Light",
		"w": 56.4,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[25.479, 0], [31.32, 0], [31.32, -61.935], [53.275, -61.935], [53.275, -67.172], [3.525, -67.172], [3.525, -61.935], [25.479, -61.935]],
							"c": true
						},
						"ix": 2
					},
					"nm": "T",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "T",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "M",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 78.9,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"o": [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
							"v": [[65.359, 0], [71.201, 0], [71.201, -67.172], [62.54, -67.172], [39.78, -11.481], [16.919, -67.172], [8.359, -67.172], [8.359, 0], [14.099, 0], [14.099, -59.619], [38.672, 0], [40.887, 0], [65.359, -59.619]],
							"c": true
						},
						"ix": 2
					},
					"nm": "M",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "M",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}, {
		"ch": "G",
		"size": 8.33333015441895,
		"style": "Light",
		"w": 71.1,
		"data": {
			"shapes": [{
				"ty": "gr",
				"it": [{
					"ind": 0,
					"ty": "sh",
					"ix": 1,
					"ks": {
						"a": 0,
						"k": {
							"i": [[0, -20.746], [-18.933, 0], [-6.042, 6.647], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [8.963, 0], [0, 17.322], [-15.811, 0], [-4.733, -5.64], [0, 0], [11.078, 0]],
							"o": [[0, 20.746], [10.574, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [-3.726, 3.726], [-15.811, 0], [0, -17.523], [8.56, 0], [0, 0], [-6.143, -7.05], [-18.933, 0]],
							"v": [[5.841, -33.536], [39.88, 1.309], [65.359, -9.869], [65.359, -33.334], [34.14, -33.334], [34.14, -28.098], [59.619, -28.098], [59.619, -12.085], [39.88, -3.928], [11.884, -33.536], [39.88, -63.043], [60.828, -53.677], [65.158, -56.799], [39.88, -68.28]],
							"c": true
						},
						"ix": 2
					},
					"nm": "G",
					"mn": "ADBE Vector Shape - Group",
					"hd": false
				}],
				"nm": "G",
				"np": 3,
				"cix": 2,
				"bm": 0,
				"ix": 1,
				"mn": "ADBE Vector Group",
				"hd": false
			}]
		},
		"fFamily": "Proxima Nova"
	}]
};